import { useContext, useEffect, useRef, useState, useCallback } from "react";
import HeaderFilterSection from "components/HeaderFilterSection";
import HeaderFilterSearchPage from "components/HeaderFilterSearchPage.js";
import ProductCard from "components/ProductCard";
import { usePriceRange } from "components/contextApi/PriceRangeProvider";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "components/contextApi/ChangeLanguage";
import { useLocation } from "react-router-dom";

// Debounce function to limit the rate of fetchData calls
const debounce = (func, delay) => {
  let timer;
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => func.apply(context, args), delay);
  };
};

const SectionGridFeatureItems = () => {
  const isMounted = useRef(false);
  const { getDatafiltered } = usePriceRange();
  const path = useLocation();
  const pathName = path.pathname;
  const {
    products,
    currentPage,
    setCurrentPage,
    load,
    setLoad,
    setProducts,
    setFilteredProducts,
    filteredProducts,
    selectedColor,
    selectedSizes,
    selectedBrands,
    selectedMainId,
    selectedSubId,
    selectedHard,
    selectedCapacities,
  } = usePriceRange();

  const { lang } = useContext(LanguageContext);
  const { t } = useTranslation();
  const [hasMore, setHasMore] = useState(true);
  const observer = useRef();

  // Combined filters into a single state object
  const filters = {
    color_id: selectedColor,
    display_size: selectedSizes,
    manufacturer_id: selectedBrands,
    main_category_id: selectedMainId,
    sub_category_id: selectedSubId,
    hard_type: selectedHard,
    hard_capacity: selectedCapacities,
  };

  const lastProductElementRef = useCallback(
    (node) => {
      if (load) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore && !load) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [load, hasMore]
  );

  // Effect for handling filter changes
  useEffect(() => {
    // Reset state on filter change
    setFilteredProducts([]); // Clear filtered products for new filter criteria
    setProducts({}); // Clear products to prevent old data showing up during fetching
    setCurrentPage(1); // Reset page number
    setHasMore(true); // Reset hasMore flag
    fetchData(true); // Fetch data with new filters
  }, [
    selectedColor,
    selectedSizes,
    selectedBrands,
    selectedMainId,
    selectedSubId,
    selectedHard,
    selectedCapacities,
  ]); // Dependencies include all selected filters

  // Fetch data function, accepting a flag to distinguish between filter and scroll
  const fetchData = useCallback(
    async (isFiltering = false) => {
      try {
        setLoad(true);
        const filterParams = {
          ...filters,
          paginate: 8,
        };

        // If not filtering (infinite scroll), add page parameter
        if (!isFiltering) {
          filterParams.page = currentPage;
        }

        const res = await getDatafiltered(filterParams, lang);

        if (res && res.data) {
          if (isFiltering) {
            // If filtering, replace products with fetched data
            setProducts(res);
            setFilteredProducts(res.data);
          } else {
            // For infinite scroll, append new data
            setProducts((prevProducts) => ({
              ...res,
              data: [...(prevProducts?.data || []), ...res.data],
            }));
          }
          setHasMore(res.data.length > 0); // Update hasMore based on fetched data
        }
        setLoad(false);
      } catch (error) {
        setLoad(false);
        console.log(error.message);
      }
    },
    [filters, currentPage, lang]
  );

  // Debounced version of fetchData
  const debouncedFetchData = useCallback(debounce(fetchData, 300), [fetchData]);

  // Effect to fetch data for infinite scrolling when currentPage changes
  useEffect(() => {
    if (currentPage > 1) {
      fetchData(false);
    }
  }, [currentPage]); // Trigger fetch on page change

  return (
    <div
      className="nc-SectionGridFeatureItems relative"
      style={{ direction: `${lang === "ar" ? "rtl" : "ltr"}` }}
    >
      <HeaderFilterSearchPage />
      <div className={`grid gap-4 grid-cols-2 lg:grid-cols-3 xl:grid-cols-4`}>
        {filteredProducts?.length > 0 &&
          filteredProducts.map((item, index) => {
            if (filteredProducts.length === index + 1) {
              return (
                <div ref={lastProductElementRef} key={index}>
                  <ProductCard data={item} />
                </div>
              );
            } else {
              return <ProductCard data={item} key={index} />;
            }
          })}
      </div>
      {load && (
        <div className="flex items-center justify-center h-24 bg-white bg-opacity-80 inset-0 z-50">
          <div className="w-16 h-16 border-4 border-[#FC5E02] border-solid border-t-transparent rounded-full animate-spin"></div>
        </div>
      )}
      {!hasMore && <p className="text-center mt-6"></p>}
    </div>
  );
};

export default SectionGridFeatureItems;
